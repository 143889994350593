import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // mode:'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/login.vue'),

    },  {
      path: '/register',
      name: 'register',
      component: () => import('@/views/register.vue'),

    }, {
      path: '/welcome',
      name: 'welcome',
      component: () => import('@/views/pages/welcome.vue'),

    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home.vue'),
      // redirect: 'index',
      // children: [{
      //   path: '/index',
      //   name: 'index',
      //   component: () => import('@/views/pages/index.vue'),
      // }]
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/pages/payment.vue'),
    },
    {
      path: '/interface',
      name: 'interface',
      component: () => import('@/views/pages/interface.vue'),
    },
    {
      path: '/control',
      name: 'control',
      component: () => import('@/views/pages/control.vue'),
    }
  ],
  linkActiveClass: 'active', // 配置模糊匹配的类名
  // linkExactActiveClass: 'exact-active' // 配置精确匹配的类名
})
const changePush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}