import Vue from 'vue'
import App from './App.vue'

//⭐引入router
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/reset.css'
import './styles/common.css'
import './assets/iconfont/iconfont.css'
import '@/assets/fonts/fonts.css';

// import FastClick from 'fastclick';
import BaiduMap from 'vue-baidu-map';

// FastClick.attach(document.body);
// console.log(store, 'store');
// console.log(router, 'router')
Vue.use(BaiduMap, {
  ak: 'GRwPoYBUrbhZLUXj3AUiGaMBoNnm0eju' // 这里填写你的百度地图ak
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
console.log(process.env.VUE_APP_BASE_URL, 'process.env.VUE_APP_BASE_URL')

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
